body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0; /* Color de fondo para toda la página */
}

.page-wrapper {
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
}

  
  @keyframes tiltBounce {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.75;
    }
  }
  
  .bold {
    font-weight: bold;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-35px);
    }
    60% {
      transform: translateY(-20px);
    }
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in {
    animation: fade-in 1s ease-in forwards;
  }
  
  .fade-in.delay-0 {
    animation-delay: 0s;
  }
  
  .fade-in.delay-1 {
    animation-delay: 0.5s;
  }
  
  .fade-in.delay-2 {
    animation-delay: 1s;
  }
  @keyframes blink {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 0.7;
    }
  }
  @keyframes flash {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 0.7;
    }
  }
  